import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userDetails:any;
  userDetailUpdate:Subject<boolean>=new Subject();
  currentTab:Subject<string>=new Subject();
  constructor( private router:Router) { }
  setToken(token:string){
    localStorage.setItem("token",token);
  }
  clearToken(){
    localStorage.removeItem('token');
    this.userDetailUpdate.complete();
  }
  setUserDetails(data:any){
    localStorage.setItem('userRoleId',data.userRoleId);
    localStorage.setItem('roleName',data.roleName);
    localStorage.setItem('emailAddress',data.emailAddress);
    localStorage.setItem('firstName',data.firstName);
    localStorage.setItem('lastName',data.lastName);
    if(data.userRoleId===2 && data.roleName==='User'){
      this.router.navigate(['/user-referrals/add-refer']);
    }else{
      this.router.navigate(['/dashboard']);
    }
    this.updateUser();
  }
  updateTab(path:string){
    this.currentTab.next(path);
  }
  getRoleName(){
    return localStorage.getItem('roleName');
  }
  updateUser(){
    this.userDetailUpdate.next(true);
  }
}
